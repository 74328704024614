import React, { useState , useEffect } from "react";
import Slider from "react-slick";
import { useStaticQuery , graphql } from "gatsby";
import scrollIcon from '../../images/mouse.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'


const HeroSlider = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.
    
    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                heroSliders {
                    nodes {
                        id
                        acfheroSlider {
                            mainSlider {
                                title
                                image {
                                    sourceUrl
                                    altText
                                }
                                description
                                
                            }
                        }
                    }
                }
            }

        }
    `)
    let curr_state_data = data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData, updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 

    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`,{

           method: 'POST',

           headers: {
                'Content-Type': 'application/json'
           },

           body: JSON.stringify({
                query:`
                {
                    heroSliders {
                        nodes {
                            id
                            acfheroSlider {
                                mainSlider {
                                    title
                                    image {
                                        sourceUrl
                                        altText
                                    }
                                    description
                                    
                                }
                            }
                        }
                    }
                }
                `
           }),
            
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occured! The Error: ${error}`));

    }, [])

    
    // Storing the state data in variables to render in Component

    let slider_data = existingData.heroSliders.nodes
    let settings = {
        dots: true,
        arrow: false,
        accessibility: true,
        adaptiveHeight: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div id="banner_section">
             <div className="img">
                <img src={slider_data[0].acfheroSlider.mainSlider.image.sourceUrl} className="slider_img" alt={slider_data[0].acfheroSlider.mainSlider.image.altText}/>
           
            <div className="overlay"></div>
           
            <Slider {...settings} className="hero_slider">
                
                {
                    slider_data.map((item) =>{
                        return <div key={item.id} className=""> 
                        
                            <div className="slide_text">

                                <h2 className="slide_title" dangerouslySetInnerHTML={{ __html: `${item.acfheroSlider.mainSlider.title}` }}></h2>
                                <p className="slide_descr">{item.acfheroSlider.mainSlider.description}</p>
                                {/* <a href={item.acfheroSlider.mainSlider.button.url} className="read_more_btn">{item.acfheroSlider.mainSlider.button.title}</a> */}

                            </div>
                        </div>
                    })
                }

            </Slider>
            <div className="fix_scroll_btn xs-hide">
                <div className="img">
                <AnchorLink offset='74'  href = "#invest_now_section">
                <img src={scrollIcon} alt="scroll_btn" />
                </AnchorLink>
                </div>

            </div>

            <div className="find_out_btn">
                <AnchorLink offset='74'  href = "#site_footer" className="find_more_btn">
                Find Out More
                </AnchorLink>
            </div>
            </div>
        </div>
    );

} 


export default HeroSlider;