import React, { useState , useEffect } from "react";
import { useStaticQuery , graphql } from "gatsby";


const Pricing = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                pricings {
                    nodes {
                        acfPricing {
                            title
                            subtext
                            backgroundImage {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
                pricingTables (where: {orderby: {field: IN, order: DESC}}) {
                    nodes {
                        title
                        id
                        acfPricingTable {
                            tableRow {
                                serviceName
                                standardPrice
                                advancedPrice
                                bespokePrice
                            }
                        }

                    }
                }
            }

        }
    `)
    let curr_state_data = data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData , updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 

    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`,{
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                query:`
                {
                    pricings {
                        nodes {
                            acfPricing {
                                title
                                subtext
                                
                            }
                        }
                    }
                    pricingTables (where: {orderby: {field: IN, order: DESC}}) {
                        nodes {
                            title
                            id
                            acfPricingTable {
                                tableRow {
                                    serviceName
                                    standardPrice
                                    advancedPrice
                                    bespokePrice
                                }
                            }
                            
                        }
                    }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));

    }, [])
    
    // console.log(existingData)

    // Storing the state data in variables to render in Component

    let pricing_txt_data = existingData.pricings.nodes[0];
    let pricing_table_data = existingData.pricingTables.nodes;

    return(
        <section id="pricing_section">
                <div className="innerwrap">
                    
                    <div className="banner_div">
                        

                        <div className="content">

                            <div className="overlay"></div>

                            <div className="section_header text-center">
                                <h3 className="section_title">{pricing_txt_data.acfPricing.title}</h3>
                                <p className="slide_descr">{pricing_txt_data.acfPricing.subtext}</p>
                            </div>


                            <div className="pricing">

                                <table className="pricing_table">
                                    <thead className="t_header">
                                        <tr>
                                            {
                                                pricing_table_data.map((item)=>{
                                                    return <th key={item.id}>
                                                        {item.title}
                                                    </th>
                                                })
                                            }
                                        </tr>
                                    </thead>

                                    <tbody className="t_body">
                                        
                                        {
                                            pricing_table_data.map((item,index)=>{

                                                var show_first_row_td = (index === 0) ? 'show center' : 'hide';
                                                var first_itr = (index===0) ? true : false;

                                                if(first_itr){

                                                    return <tr key={item.id}>
                                                        
                                                        <td>
                                                            {item.acfPricingTable.tableRow.serviceName} 
                                                        </td>

                                                        <td>
                                                           {item.acfPricingTable.tableRow.standardPrice} 
                                                        </td>

                                                        <td>
                                                           {item.acfPricingTable.tableRow.advancedPrice} 
                                                        </td>
                                                        

                                                        <td rowSpan="4" className={show_first_row_td} dangerouslySetInnerHTML={{ __html: `${item.acfPricingTable.tableRow.bespokePrice}` }}>
                                                            
                                                        </td>


                                                    </tr>
                                                }

                                                else{
                                                    return <tr key={item.id}>
                                                        
                                                        <td>
                                                            {item.acfPricingTable.tableRow.serviceName} 
                                                        </td>

                                                        <td>
                                                           {item.acfPricingTable.tableRow.standardPrice} 
                                                        </td>

                                                        <td>
                                                           {item.acfPricingTable.tableRow.advancedPrice} 
                                                        </td>
                                                    
                                                    </tr>
   
                                                }
                                            }) 
                                        }

                                    </tbody>
                                </table>
                                
                            </div>
                        </div>

                    </div>

                </div>
        </section>
    )    
}

export default Pricing;