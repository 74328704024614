import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroSlider from "../components/Heroslider/HeroSlider"
import FreeDemo from "../components/FreeDemo/FreeDemo"
import Features from "../components/Features/Features"
// import Funds from "../components/Funds/Funds"
import Security from "../components/Security/Security"
import Pricing from "../components/Pricing/Pricing"
// import Image from "../components/image"

const IndexPage = () => (

    <Layout>
        <SEO title="Home" />
        <HeroSlider/>
        <FreeDemo/>
        <Features/>
        {/* <Funds/> */}
        <Security/>
        <Pricing/>
    </Layout>
)

export default IndexPage

