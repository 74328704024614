import React, { useState , useEffect } from "react";
import { useStaticQuery , graphql } from "gatsby";
import laptop_mock from '../../images/laptop_mock_new.png';

const FreeDemo = () => {
    
    // Querying and storing Data Stored in Gatsby GraphQL query.

    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                investNowSection {
                    nodes {
                        id
                        acfInvestNow {
                            title
                            subtext
                            imageText
                            image {
                                sourceUrl
                                altText
                            }
                        }
                    }
                }
            }

        }
    `)
    let curr_state_data = data_query.wpgraphql;
    // console.log(curr_state_data)

    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData , updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 
    
    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`,{
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                query:`
                {
                    investNowSection {
                        nodes {
                            id
                            acfInvestNow {
                                title
                                subtext
                                imageText
                            }
                        }
                    }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));

    }, [])
    
    // console.log(existingData)

    // Storing the state data in variables to render in Component

    let section_data = existingData.investNowSection.nodes[0];
    return(
        <section id="invest_now_section">

                <div className="innerwrap">

                    <div className="section_header text-center">
                        <h3 className="section_title">{section_data.acfInvestNow.title}</h3>
                        <p className="slide_descr">{section_data.acfInvestNow.subtext}</p>
                    </div>


                    <div className="mock_image_div">
                        
                        <div className="overlay"></div>

                        <div className="mock_img_wrapper">
                            <div className="laptop_mock">
                                <img src={laptop_mock} alt="Laptop Mock"/>

                                
                            </div>

                        </div>

                        <div className="text_wrap text-center">
                            <div className="inner_text">
                                <hr />
                                <p dangerouslySetInnerHTML={{ __html: `${section_data.acfInvestNow.imageText}` }}></p>
                            </div>
                        </div>
                        
                     </div>

                </div>

        </section>
    );
}

export default FreeDemo;