import React, { useState , useEffect } from "react";
import { useStaticQuery , graphql } from "gatsby";
import acc_open from '../../images/accordion-open.png';
import acc_close from '../../images/accordion-close.png';
import feature_img from '../../images/feature_img.svg';
var showData = '';
const Features = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.

    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                features {
                    nodes {
                        acffeatures {
                            title
                            subtext
                            image {
                                altText
                                sourceUrl
                            }
                            moduleTitle
                        }
                    }
                }
                featureAccordions  (where: {orderby: {field: IN, order: DESC}}) {
                    nodes {
                        id
                        acfFeatureAccordions {
                            moduleName
                            moduleDescription
                        }
                    }
                }
            }

        }
    `)
    let curr_state_data = data_query.wpgraphql;

    // Setting states for accordion 

    const [showDropData, setShowDropData] = useState("");

    function toggleAccordion(index){
        var value = index;
        if(value == showDropData){
            showData = '';
            setShowDropData("")
        }else{
            showData = value;
            setShowDropData(showDropData => value)
        }
        
        
    }


    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData , updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 

    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;
        
        fetch(`${curr_api}/graphql`,{
            method : 'POST',
            headers : {
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                query:`
                {
                    features {
                        nodes {
                            acffeatures {
                                title
                                subtext
                                image {
                                    altText
                                    sourceUrl
                                }
                                moduleTitle
                            }
                        }
                    }
                    featureAccordions (where: {orderby: {field: IN, order: DESC}}) {
                        nodes {
                            id
                            acfFeatureAccordions {
                                moduleName
                                moduleDescription
                            }
                        }
                    }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));

    }, [])

    // console.log(existingData)

    let features_txt_data = existingData.features.nodes[0];
    let features_acc_data = existingData.featureAccordions.nodes;

    // Storing the state data in variables to render in Component

    return(
        <section id="features_section">
                <div className="innerwrap">
                
                    <div className="section_header text-center">
                        <h3 className="section_title">{features_txt_data.acffeatures.title}</h3>
                        <p className="slide_descr">{features_txt_data.acffeatures.subtext}</p>
                    </div>

                    <div className="features_div">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xs-12 col-sm-6">
                                    <div className="left_img">
                                        {/* {feature_img} */}
                                        <img src={feature_img} className="features_img" alt={features_txt_data.acffeatures.image.altText}/>

                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-6">
                                    <div className="features_wrapper">

                                        <div className="features_title">
                                            <h3 className="title">{features_txt_data.acffeatures.moduleTitle}</h3>
                                        </div>

                                        <div className="accordion_wrapper">
                                            <ul className="feature_accordion">

                                                {
                                                    features_acc_data.map((item,index) =>{
                                                        return <li key={item.id} className = 'accordion_item'>
                                                        
                                                                <div id={item.id} className = {showData != "" ? showData == item.id ? `header active`  : "header" : "header"} onClick={() =>toggleAccordion(item.id)}>
                                                                    <span className="toggle_icon">
                                                                        <img src={acc_open} className="accordion_open" alt="Features icon"/>
                                                                        <img src={acc_close} className="accordion_close" alt="Features icon"/>

                                                                    </span>
                                                                    <span className="accordion_name">{item.acfFeatureAccordions.moduleName}</span>
                                                                </div>
                                                    
                                                                <div className="content">
                                                                    <p className="accordion_content">
                                                                    {item.acfFeatureAccordions.moduleDescription}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        })
                                                }

                                                

                                                
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
        </section>
    )

}
 
export default Features;