
import React, { useState , useEffect } from "react";
import { useStaticQuery , graphql } from "gatsby";
import mac_mock from '../../images/mac_mock.png';
import mac_mock_xs from '../../images/Finalmockups_xs.png';
const Security = () => {

    // Querying and storing Data Stored in Gatsby GraphQL query.
    
    const data_query = useStaticQuery(graphql`
        query{
            wpgraphql {
                securities {
                    nodes {
                        acfSecurity {
                            title
                            subtext
                            backgroundImage {
                                altText
                                sourceUrl
                            }
                            contentText
                        }
                    }
                }
            }
        }

    `)

    let curr_state_data = data_query.wpgraphql;

    // Storing this data as the State so that we can update the state later with Live Data
    const[ existingData , updateData ] = useState(curr_state_data);

    // Fetching the Live data from the created WORDPRESS API 

    useEffect(()=>{
        let curr_api = process.env.GATSBY_API_URL;

        fetch(`${curr_api}/graphql`, {
            method : 'POST',

            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify({
                query:`
                {
                    securities {
                        nodes {
                            acfSecurity {
                                title
                                subtext
                                contentText
                            }
                        }
                    }
                }
                `
            }),
        })
        .then(res => res.json())
        .then(data => updateData(data.data))
        .catch(error => console.log(`Some Error Occurred! The Error :${error}`));
    }, [])
    
    // console.log(existingData)

    // Storing the state data in variables to render in Component
    
    let security_data = existingData.securities.nodes[0];

    return(
        <section id="security_section">
                <div className="innerwrap">

                    <div className="section_header text-center">
                        <h3 className="section_title">{security_data.acfSecurity.title}</h3>
                        <p className="slide_descr">{security_data.acfSecurity.subtext}</p>
                    </div>

                    <div className="banner_image">

                        <div className="overlay"></div>

                        <div className="mock_img xs-hide">
                            <img src={mac_mock} alt="mock_img"/>
                        </div>
                        <div className="mock_img xs-show">
                            <img src={mac_mock_xs} alt="mock_img_xs"/>
                        </div>
                        
                        <div className="text_wrap">
                            <div className="inner_text" dangerouslySetInnerHTML={{ __html: `${security_data.acfSecurity.contentText}` }} >
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
        </section>
    )
}

export default Security;